<template>
  <div style="height: 100%; padding: 56px 14px; background-color: #F7F8F9;">
    <v-tabs
      v-model="tab"
      background-color="primary"
      dark
    >
      <v-tab
        v-for="item in items"
        :key="item.tab"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <div class="top_info_switch_wrap">
      <div class="top_info_usage">
        <div class="usage_chip">
          기간사용량
        </div>
        <div>흑백 {{ totalByPeriodComputedMono | number }}</div>
        <div>컬러 {{ totalByPeriodComputedColor | number }}</div>
        <div>합계 {{ totalByPeriodComputed | number }}</div>
      </div>
      <div>
        <vue-switch
          v-model="usageFlag"
          refs="switch"
          color="#BCD1E2"
          :true-text="'사용량'"
          :false-text="'누적 사용량'"
          true-text-color="#ffffff"
          true-background-color="#5a5a5a"
          false-text-color="#ffffff"
          false-background-color="#5a5a5a"
          :class="usageFlag ? 'switch usage' : 'switch cumulative_usage'"
        />
      </div>
    </div>
    <template v-if="!loading">
      <v-tabs-items v-model="model">
        <v-tab-item
          :key="0"
        >
          <v-card flat>
            <v-card-text>
              <printer-daily-usage-tabs-items
                v-if="model === 0"
                :usage-flag="usageFlag"
                :printer-items="printerItems"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item
          :key="1"
        >
          <v-card flat>
            <v-card-text>
              <printer-daily-usage-tabs-items
                v-if="model === 1"
                :usage-flag="usageFlag"
                :printer-items="printerItems"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-if="loading">
      <progress-loading />
    </template>
  </div>
</template>

<script>
    import {PATH} from "@/constants";
    import printerDailyUsageTabsItems from "@/components/windows/printer/PrinterDailyUsageTabsItems";
    import progressLoading from "@/components/part/ProgressLoading";


    export default {
        name: "PrinterDailyUsageComp",
        components: {printerDailyUsageTabsItems, progressLoading},
        props: {
            printer: undefined,
            searchOptions: Object,
            identifier: Number,
        },
        data() {
            return {
                model: 0,
                internalValue: undefined,
                tab: null,
                usageFlag: true,
                items: [
                    {tab: 'One', content: 'Tab 1 Content'},
                    {tab: 'Two', content: 'Tab 2 Content'},
                ],
                printerItems: [],
                usageStatistics: {
                    colorCountByPeriod: 0,
                    monoCountByPeriod: 0,
                },
                loading: false,

            }
        },
        computed: {
            totalByPeriodComputed() {
                if (this.printerItems.length < 1) return 0
                const dayEngineTotalCount = this.printerItems
                    .map((e) => e.dayEngineCount)
                    .reduce((a, b) => a + b)
                const dayTotalCount3 = this.printerItems
                    .map((e) => e.dayTotalCount3 * this.a3Double(e))
                    .reduce((a, b) => a + b)
                const dayTotalCount4 = this.printerItems
                    .map((e) => e.dayTotalCount4)
                    .reduce((a, b) => a + b)
                return dayEngineTotalCount > 0
                    ? dayEngineTotalCount
                    : dayTotalCount3 + dayTotalCount4 || 0
            },
            totalByPeriodComputedMono() {
              if (this.printerItems.length < 1) return 0

              const dayMonoCount3 = this.printerItems
                      .map((e) => e.dayMonoCount3 * this.a3Double(e))
                      .reduce((a, b) => a + b)
              const dayMonoCount4 = this.printerItems
                      .map((e) => e.dayMonoCount4)
                      .reduce((a, b) => a + b)
              return dayMonoCount3 + dayMonoCount4 || 0
            },
            totalByPeriodComputedColor() {
              if (this.printerItems.length < 1) return 0

              const dayColorCount3 = this.printerItems
                      .map((e) => e.dayColorCount3 * this.a3Double(e))
                      .reduce((a, b) => a + b)
              const dayColorCount4 = this.printerItems
                      .map((e) => e.dayColorCount4)
                      .reduce((a, b) => a + b)
              return dayColorCount3 + dayColorCount4 || 0
            },
        },
        watch: {
            usageFlag() {
                if (this.usageFlag) {
                    this.model = 0
                } else {
                    this.model = 1
                }
            },
            model() {
                if (this.model === 0) {
                    this.usageFlag = true
                } else {
                    this.usageFlag = false
                }
            },
            identifier() {
                this.init()
            },
        },
        mounted() {
            this.init()
        },
        methods: {

            async init() {
                if (!this.searchOptions.state) return
                this.usageFlag = true
                this.loading = true

                const requestArr = []

                requestArr.push(this.loadPrinterDailyUsage())
                requestArr.push(this.statisticsLoad())

                const result = await Promise.all(requestArr)
                this.printerItems = result[0].data.map((e) => ({
                    ...e,
                    isA3Monitoring: this.isA3Monitoring(e),
                }))
                this.usageStatistics = result[1]

                this.loading = false
            },

            a3Double(item) {
                return (!!item.finalMonoPageCount3 &&
                    item.finalMonoPageCount3 * 2 + item.finalMonoPageCount4 ===
                    item.finalMonoPageCount) ||
                (!!item.finalColorPageCount3 &&
                    item.finalColorPageCount3 * 2 + item.finalColorPageCount4 ===
                    item.finalColorPageCount)
                    ? 2
                    : 1
            },

            isA3Monitoring(item) {
                return !!item?.a3MonitoringYn && item.a3MonitoringYn === 'Y'
            },


            async loadPrinterDailyUsage(params = this.searchOptions.state) {
                if (!this.searchOptions.state) return
                params.monitoringPrinterContractId =
                    this.printer.monitoringPrinterContractId
                return await this.$request(PATH.PRINTER_USAGE_HISTORY_LIST)
                    .setObject(
                        params
                    ).enqueue()
            },

            async statisticsLoad(params = this.searchOptions.state) {
                if (!this.searchOptions.state) return
                params.monitoringPrinterContractId =
                    this.printer.monitoringPrinterContractId

                return this.$request(PATH.PRINTER_USAGE_BY_PERIOD_ON_ACCOUNT)
                    .setObject(params)
                    .enqueue()

            },

        },
    }
</script>

<style scoped>
    >>> .v-tabs {
        display: none;
    }

    .v-tabs-items {
        height: 100%;
        overflow-y: auto;
    }

    .v-card__text {
        padding: 14px 0px;
    }

    .top_info_switch_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }

    .top_info_usage {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
    }

    .top_info_usage .usage_chip {
        background-color: #D5EAFA;
        color: #0c98fe;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 6px;
        margin-right: 8px;
        min-width: 88px;
    }

    .top_info_usage div:not(.usage_chip) {
        margin-right: 6px;
    }

    table {
        width: 100%;
        line-height: 34px;
        border-spacing: 0;
    }

    table tr th {
        color: #333;
    }

    table tr td {
        border-bottom: 1px solid #ddd;
        color: #333;
    }

    >>> .usage .v-input--selection-controls__input > .v-input--switch__track {
        width: 82px !important;
    }

    >>> .usage .v-input--selection-controls__input {
        width: 77px !important;
    }

    >>> .usage .v-input--switch__thumb {
        left: 34px !important;
        background-color: white !important;
    }

    >>> .cumulative_usage .v-input--selection-controls__input > .v-input--switch__track {
        width: 112px !important;
    }

    >>> .cumulative_usage .v-input--selection-controls__input {
        width: 103px !important;
        position: relative;
    }

    >>> .cumulative_usage .v-input--switch__thumb {
        left: 2px !important;
        background-color: white !important;
    }

    .switch {
        font-size: 0.875rem;
        line-height: 22px;
    }

    >>> .false_text {
        position: absolute;
        right: 11px;
        top: 4px;
        pointer-events: none;
    }

    >>> .v-card__text {
        padding: 0px 10px 10px;
    }

    >>> .v-input--switch--inset .v-input--switch__track {
        opacity: 1;
    }
</style>
