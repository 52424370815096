<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <my-tool-bar
      :action-mode="actionMode"
      :loading="loading || loadingPrinterContract"
      :searchable="true"
      :title="title | translate"
    >
      <template #action>
        <toolbar-modify-action
          @close="tryToCloseEditMode"
          @save="onSave"
        />
      </template>
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
          @click="onModifyModeClicked"
        >
          <!--<v-icon>mdi-file-document-edit</v-icon>-->
          <img src="@/assets/images/main/svg/button_Edit.svg">
        </v-btn>
      </template>
    </my-tool-bar>

    <div style="position: relative; top: 56px; height: calc(100% - 56px);">
      <template v-if="!!printerContractId || !!monitoringPrinterId">
        <template v-if="!loadingPrinterContract">
          <!-- 프린터 계약 정보입니다. -->
          <group-header :props-data="{ title: this.$translate('프린터 계약정보') }" />
          <template v-if="!!printerContractData">
            <m-form
              :form-data="printerContractData.contractPrinter"
              :form-info="printerContractInfo"
              class="overflow-y-auto"
              form-provider="mps"
            />
          </template>

          <template v-else>
            <no-items
              :title="'프린터 계약이 없습니다.' | translate"
            />
          </template>

          <!-- 프린터 기본 정보. Read Only 전용입니다. -->
          <template v-if="!!defaultData">
            <m-form
              :form-data="defaultData"
              :form-info="defaultInfo"
              class="overflow-y-auto"
              form-provider="mps"
            />
          </template>
        </template>

        <template v-if="!loading && !loadingPrinterContract">
          <!-- 모니터링 정보입니다. -->
          <group-header :props-data="{ title: this.$translate('모니터링 정보') }" />
          <template v-if="!!formData">
            <m-form
              ref="form"
              :edit.sync="editMode"
              :form-data.sync="formData"
              :form-info="formInfo"
              class="overflow-y-auto"
              form-provider="mps"
            />
          </template>
          <template v-else>
            <no-items
              :title="'모니터링 정보가 없습니다' | translate"
            />
          </template>
        </template>
        <template v-if="formData && formData.printerModelType === '1'">
          <group-header :props-data="{ title: this.$translate('토너 재고 잔량') }" />
          <v-btn
            class="justify-space-between mb-3"
            color="primary"
            elevation="0"
            height="62px"
            tile
            width="100%"
            style="background-color: #fff !important;"
            @click="onScheduleClicked"
          >
            <span>{{ '토너 재고 잔량 상세' | translate }}</span>
            <span>
              <img
                src="@/assets/images/main/svg/acceptAarrow.svg"
                style="padding-top: 2px; width: 8px;"
              ></span>
          </v-btn>
        </template>
      </template>
      <template v-else>
        <invalid-data />
      </template>
    </div>
  </div>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import MForm from "@/assets/plugins/mps-form/MForm";
    import {MAXLENGTH, PATH} from "@/constants";
    import ToolbarModifyAction from "@/components/app/toolbar/ToolbarModifyAction";
    import InvalidData from "@/components/part/InvalidData";
    import GroupHeader from "@/assets/plugins/mps-form/providers/components/group/GroupHeader";
    import NoItems from "@/components/items/NoItems";
    import BackGuardEditModeInterface from "@/components/windows/mixins/BackGuardEditModeInterface";
    import Number from "@/assets/plugins/mps-common/expansions/internal/number";
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import Permissions from "@/constants/permissions";

    export default {
        name: "PrinterDetail",
        components: {
            NoItems,
            GroupHeader,
            InvalidData, ToolbarModifyAction, MForm, MyToolBar
        },
        extends: WindowComponent,
        mixins: [BackGuardEditModeInterface],
        props: {
            printerContractId: undefined,
            monitoringPrinterId: undefined,
        },
        data() {
            return {
                loading: false,
                loadingPrinterContract: false,
                updating: false,
                actionMode: false,
                alreadyLoaded: false,
                // 계약 정보
                printerContractData: undefined,
                printerContractInfo: [
                    {
                        mutable: false,
                        title: this.$translate('계약번호'),
                        key: 'contractNo',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('신규여부'),
                        key: 'contractTypeName',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('기본요금'),
                        key: 'contractFee',
                        read: (inEditMode, data) => this.salesConfigPermission ? this.$number(data.contractFee) : 'HIDDEN',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('계약방식'),
                        key: 'realYnName',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('계약일'),
                        key: 'contractDate',
                        formReadType: this.$mps.form.types.DATE_PICKER,
                        formWriteType: this.$mps.form.types.DATE_PICKER,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('계약기간'),
                        key: 'contractStartdate',
                        read: (inEditMode, data) => data.contractStartdate === null ? '-' : data.contractStartdate.toString() + ' ~ ' + data.contractEnddate.toString(),
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('해지일'),
                        key: 'contractCanceldate',
                        formReadType: this.$mps.form.types.DATE_PICKER,
                        formWriteType: this.$mps.form.types.DATE_PICKER,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('임대방식'),
                        key: 'rentTypeName',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('컬러'),
                        key: 'colorYn',
                        read: (inEditMode, data) => data.colorYn === "Y" ? this.$translate("컬러") : this.$translate("흑백"),
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                  {
                    mutable: false,
                    title: this.$translate('기본매수'),
                    key: 'basePagecount',
                    read: (inEditMode, data) => {

                      try {
                        const mCnt = (data?.basePagecount || 0).toLocaleString()
                        const cCnt = (data?.basePagecountColor || 0).toLocaleString()
                        return `(M) ${mCnt} ${data?.colorYn === "Y" ? ' / (C) ' + cCnt : ''}`
                      } catch (e) {
                        return '-'
                      }
                    },
                    formReadType: this.$mps.form.types.TEXTAREA,
                    formWriteType: this.$mps.form.types.TEXTAREA,
                    options: {}
                  },
                  {
                    mutable: false,
                    title: this.$translate('추가요금(1매)'),
                    key: 'additionalFee',
                    read: (inEditMode, data) => {
                      try {
                        const mFee = (data?.additionalFee || 0).toLocaleString()
                        const cFee = (data?.additionalFeeColor || 0).toLocaleString()
                        return `(M) ${mFee} ${data?.colorYn === "Y" ? ' / (C) ' + cFee : ''}`
                      } catch (e) {
                        return '-'
                      }
                    },
                    formReadType: this.$mps.form.types.TEXTAREA,
                    formWriteType: this.$mps.form.types.TEXTAREA,
                    options: {}
                  },
                ],
                // 프린터 기본 정보
                defaultData: undefined,
                defaultInfo: [
                    {
                        component: GroupHeader,
                        propsData: {
                            title: this.$translate("기본정보"),
                        },
                    },
                    {
                        mutable: false,
                        title: this.$translate('거래처'),
                        key: 'accountName',
                        read: (inEditMode, data) => !!data.accountId ? data.accountName + "[" + data.accountId + "]" : this.$translate("본사"),
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('모델'),
                        key: 'printerModelName',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                ],
                // 모니터링 정보
                formData: undefined,
                formInfo: [
                    {
                        mutable: false,
                        title: this.$translate('프린터 상태'),
                        key: 'printerStateCodeName',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: true,
                        title: this.$translate('설치장소'),
                        key: 'installLocation',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            maxlength: MAXLENGTH.PRINTER.INSTALL_LOCATION,
                        },
                        fullWidthLabel: true,
                    },
                    {
                        mutable: false,
                        title: this.$translate('시리얼_번호'),
                        key: 'printerSerialNo',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                        },
                    },
                    {
                        mutable: false,
                        title: this.$translate('프린터IP'),
                        key: 'ip',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('장치_정보'),
                        key: 'serialNo',
                        read: (inEditMode, data) => this.$serial(data.serialNo),
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                        },
                    },
                    {
                        mutable: false,
                        title: this.$translate('무한잉크_사용여부'),
                        key: 'unlimitedInkYn',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                    },
                    {
                        mutable: false,
                        title: this.$translate('잉크센서_사용여부'),
                        key: 'inkSensorYn',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('컬러'),
                        key: 'colorYn',
                        read: (inEditMode, data) => data.colorYn === "1" ? this.$translate('컬러') : this.$translate('흑백'),
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('모델구분'),
                        key: 'printerModelTypeName',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: true,
                        title: this.$translate('구매가격'),
                        key: 'purchasedAmount',
                        formReadType: this.$mps.form.types.NUMBER,
                        formWriteType: this.$mps.form.types.NUMBER,
                        options: {}
                    },
                    {
                        mutable: false,
                        title: this.$translate('프린터 상태 메시지'),
                        key: 'printerStateMessage',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {}
                    },
                    {
                        mutable: true,
                        title: this.$translate('토너 잔량체크'),
                        key: 'pmChkToner',
                        read: (inEditMode, data) => {
                            return {
                                codeKey: data.pmChkToner === "Y" ? "Y" : "N",
                                codeValue: data.pmChkToner === "Y" ? "Y" : "N",
                            }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.pmChkToner = v.codeKey;
                        },
                        formReadType: this.$mps.form.types.OPTIONS_PICKER,
                        formWriteType: this.$mps.form.types.OPTIONS_PICKER,
                        options: {
                            items: [
                                {codeKey: "Y", codeValue: "Y"},
                                {codeKey: "N", codeValue: "N"},
                            ]
                        },
                        rules: [
                            (data) => !!data.pmChkToner || this.$translate('토너 잔량체크를 선택해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate('드럼 잔량체크'),
                        key: 'pmChkDrum',
                        read: (inEditMode, data) => {
                            return {
                                codeKey: data.pmChkDrum === "Y" ? "Y" : "N",
                                codeValue: data.pmChkDrum === "Y" ? "Y" : "N",
                            }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.pmChkDrum = v.codeKey;
                        },
                        formReadType: this.$mps.form.types.OPTIONS_PICKER,
                        formWriteType: this.$mps.form.types.OPTIONS_PICKER,
                        options: {
                            items: [
                                {codeKey: "Y", codeValue: "Y"},
                                {codeKey: "N", codeValue: "N"},
                            ]
                        },
                        rules: [
                            (data) => !!data.pmChkDrum || this.$translate('드럼 잔량체크를 선택해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate('비고'),
                        key: 'memo',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            maxlength: MAXLENGTH.PRINTER.MEMO,
                        },
                        fullWidthLabel: true,
                    },
                ],
            }
        },
      computed: {
        salesConfigPermission() {
          return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_SALES_CONFIG)
        }
      },
        watch: {
            formData: {
                deep: true,
                handler: function () {
                    this.updateOptions();
                    this.updatePropsData();
                }
            },
        },
        mounted() {
            this.loadPrinterContract();
            this.loadDetail();
        },
        methods: {
            /**
             * 토너 재고 관리 데이터 조회
             */
            async loadTonerRefill() {
              if (
                  this.formData.printerModelType !== '1' ||
                  !this.formData.refillHistory ||
                  this.formData.refillHistory === 0
              ){
               return null
              }
              const result = await this.$request(PATH.TONER_REFILL.RETRIEVE)
                  .setObject({
                    printerTonerRefillId: this.formData.refillHistory,
                  })
                  .enqueue()
              return result
            },


            async onScheduleClicked() {
              const res = await this.loadTonerRefill()


              if (!res) {
                this.$nextTick(() => {
                  this.$dialog()
                      .message(this.$translate('재고 관리중이 아닙니다. 재고관리를 시작하시겠습니까?'))
                      .buttonNegative(this.$translate('취소'))
                      .buttonPositive(this.$translate('이동'), () => {
                        this.routerManager.push({
                          name: "PrinterTonerManagementCreate",
                          params: {
                            printer: this.formData,
                          }
                        });
                      })
                      .build()
                      .show();
                })
              } else {
                this.routerManager.push({
                  name: "PrinterTonerManagementDetail",
                  params: {
                    monitoringPrinterId: this.monitoringPrinterId,
                    refillHistory: this.formData.refillHistory,
                    printerContractId: this.printerContractId,
                  }
                });
              }
            },

            updatePropsData() {
            },

            updateOptions() {
            },

            /**
             * 모니터링 정보가 변경될 때마다 options 를 변경하여 준다.
             */
            updateMonitoringOptions() {
                const serialNoInfo = this.formInfo.find(e => e.key === "serialNo");
                if (serialNoInfo) {
                    serialNoInfo.options = {
                        linkName: "DeviceDetail",
                        params: { tnDeviceId: this.formData.tnDeviceId, accountId: this.formData.accountId },
                    };
                }
            },

            async loadDetail(loading = true) {
                if (!this.monitoringPrinterId) return;
                this.loading = loading;
                try {
                    const response = await this.$request(PATH.PRINTER.GET)
                        .setObject({
                            monitoringPrinterId: this.monitoringPrinterId,
                        })
                        .wait(500)
                        .enqueue();
                    this.formData = response.data;
                    this.defaultData = {
                        accountName: this.formData.accountName,
                        accountId: this.formData.accountId,
                        printerModelName: this.formData.printerModelName,
                    };
                    this.updateMonitoringOptions();
                } catch (e) {
                    //do nothing.
                    console.error(e);
                }
                this.loading = false;
            },

            async loadPrinterContract(loading = true) {
                if (!this.printerContractId || this.printerContractId === '0') {
                  return this.printerContractData = null;
                }
                this.loadingPrinterContract = loading;
                try {
                    const response = await this.$request(PATH.PRINTER_CONTRACT.DETAIL)
                        .setObject({
                            printerContractId: this.printerContractId,
                        })
                        .enqueue();
                    this.printerContractData = response;
                    this.defaultData = {
                        accountName: this.printerContractData.accountName,
                        accountId: this.printerContractData.accountId,
                        printerModelName: this.printerContractData.printerModelName,
                    };
                } catch (e) {
                    //do nothing.
                    console.error(e);
                }
                this.loadingPrinterContract = false;
            },

            async updateDetail() {
                this.updating = true;
                try {
                    const response = await this.$requestWithOverlay(PATH.PRINTER.UPDATE)
                        .setObject(this.formData)
                        .enqueue();
                    this.setEditMode(false);
                    this.$snackbar(this.$translate("프린터 정보가 수정되었습니다."));
                    this.backGuard.pop(); // 업데이트에 성공하였다면 편집모드에서 설정된 백가드를 제거합니다.
                } catch (e) {
                    this.$snackbarError(this.$translate("서버에서 에러가 발생하였습니다."));
                }
                this.updating = false;
            },

            onModifyModeClicked() {
                this.setEditMode(true);
            },

            async onSave() {
                if (!await this.$refs.form.validate()) return;
                this.updateDetail();
            },

            setEditMode(enabled) {
                this.actionMode = enabled;
                this.editMode = enabled;
            },
        },
    }
</script>

<style scoped>
.v-application .primary {
  background-color: #fff !important;
  border: 1px solid #aaa !important;
  color: #0C98FE;
}

.v-btn__content span:first-child {
  font-size: 1.125rem;
  font-weight: bold;
}

.v-btn__content span:last-child {
  font-size: 0.875rem;
}

#app .v-application .primary {
  background-color: #fff !important;
  color: #0C98FE !important;
}
</style>
