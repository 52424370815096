<template>
  <div
    class="text-left as-content px__18"
    :class="{ expanded: expand }"
    style="height: 100%"
  >
    <v-row class="mx-0 mt-0 font__14">
      <v-col
        cols="12"
        class="pa-3"
      >
        <span>
          {{ '재고 변경 이력 보기' }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
</style>

<script>
    import {PATH} from "@/constants";
    import OnEtcRequestSuccessed from "@/event/OnEtcRequestSuccessed";

    export default {
        name: "PrinterTonerHistoryHeader",
        mixins: [OnEtcRequestSuccessed],
        props: {
            expand: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {

            }
        },
        watch: {

        },
        /**
         * 초기 로드입니다.
         */
        created() {
        },
        /**
         * 페이지를 불러올 때마다 매 번 로드합니다. 단, watch 에 의해 이미 로드된 경우 로드하지 않습니다.
         */
        mounted() {

            /**
             * 여기에서 사용하는 라이프 사이클 중 actiavted 가 가장 마지막입니다.
             * 그러므로 라이프 사이클이 다시 시작될 때 로드될 수 있도록 플래그를 false 로 바꾸어 줍니다.
             */
            this.alreadyLoadedByAccountId = false;
        },
        methods: {


        },
    }
</script>
