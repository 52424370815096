<template>
  <div>
    <div>
      <table>
        <tr>
          <th class="text-left">
            사용일자
          </th>
          <th class="text-center">
            용지
          </th>
          <th>흑백</th>
          <th>컬러</th>
          <th>합계</th>
          <th>엔진</th>
        </tr>
        <tbody
          v-for="(item,index) in printerItems"
          :key="item + index"
        >
          <tr>
            <td
              rowspan="3"
              class="text-left"
            >
              {{ item.baseDay }}
            </td>
            <td class="text-center">
              A4
            </td>
            <td>  {{ (usageFlag ? item.dayMonoCount4 : item.finalMonoPageCount4) | currency }}</td>
            <td>  {{ (usageFlag ? item.dayColorCount4 : item.finalColorPageCount4) | currency }}</td>
            <td>
              {{
                (usageFlag ? item.dayTotalCount4 : sum(item, 'finalColorPageCount4', 'finalMonoPageCount4'))
                  | currency
              }}
            </td>
            <td :rowspan="item.isA3Monitoring ? 3 : ''">
              {{ (usageFlag ? item.dayEngineCount : item.finalEngineCount) | currency }}
            </td>
          </tr>
          <tr v-if="item.isA3Monitoring">
            <td class="text-center">
              A3
            </td>
            <td>   {{ (usageFlag ? item.dayMonoCount3 : item.finalMonoPageCount3) | currency }}</td>
            <td>   {{ (usageFlag ? item.dayColorCount3 : item.finalColorPageCount3) | currency }}</td>
            <td>
              {{
                (usageFlag ? item.dayTotalCount3 : sum(item, 'finalColorPageCount3', 'finalMonoPageCount3'))
                  | currency
              }}
            </td>
          </tr>
          <tr v-if="item.isA3Monitoring">
            <td class="text-center">
              합계<span
                v-if="a3Double(item) === 2"
                class="double_count"
              >x2</span>
            </td>
            <td>  {{ (usageFlag ? (item.dayMonoCount3 * a3Double(item) + item.dayMonoCount4): item.finalMonoPageCount ) | currency }}</td>
            <td> {{ (usageFlag ? (item.dayColorCount3 * a3Double(item) + item.dayColorCount4) : item.finalColorPageCount ) | currency }}</td>
            <td> {{ (usageFlag ? (item.dayTotalCount3 * a3Double(item) + item.dayTotalCount4) :item.finalPageCount )| currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {PATH} from "@/constants";


export default {
  name: "PrinterDailyUsageTabsItems",
  components: {},
  props: {
    printerItems: undefined,
    usageFlag: Boolean,
  },
  data() {
    return {
      internalValue: undefined,
      tab: null,
      items: [
        { tab: 'One', content: 'Tab 1 Content' },
        { tab: 'Two', content: 'Tab 2 Content' },
      ],
      searchOptions: {
        pageNumber: 1,
        pageSize: 99999,
        startDate: this.$now().startOf('month').minus({ month: 3 }),
        endDate: this.$now().endOf('month'),
      },
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {
    a3Double(item) {
      return (!!item.finalMonoPageCount3 &&
          item.finalMonoPageCount3 * 2 + item.finalMonoPageCount4 ===
          item.finalMonoPageCount) ||
      (!!item.finalColorPageCount3 &&
          item.finalColorPageCount3 * 2 + item.finalColorPageCount4 ===
          item.finalColorPageCount)
          ? 2
          : 1
    },
    sum(item, key1, key2) {
      if (item.finalEngineCount) return 0
      return item[key1] + item[key2]
    },

  },
}
</script>

<style scoped>
>>>.v-tabs {
  display: none;
}

.v-card__text {
  padding: 14px 0px;
}

.top_info_switch_wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.top_info_usage {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.top_info_usage .usage_chip {
  background-color: #D5EAFA;
  color: #0c98fe;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  margin-right: 8px;
}

.top_info_usage div:not(.usage_chip) {
  margin-right: 6px;
}

table {
  width: 100%;
  line-height: 34px;
  border-spacing: 0;
  position: relative;
}

table tr th {
  color: #333;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 10px 0px 0px;
  text-align: right;
}

table tr th:first-child {
  width: 24%;
}

table tr th:nth-child(2) {
  width: 14%;
}

table tr td {
  border-bottom: 1px solid #ddd;
  color: #333;
  text-align: right;
  font-size: 0.8125rem;
}

.double_count {
  border: 1px solid #0c98fe;
  color: #0c98fe;
  width: 20px;
  text-align: center;
  border-radius: 2px;
  font-size: 0.6875rem;
  padding: 0 2px;
  margin-left: 1px;
}
</style>
